import React from "react";
import { Button } from "react-bootstrap";
import "./Home.scss";
import { Proyecto } from "../Proyecto";
import { Video } from "../Video";
import { Caracteristicas } from "../Caracteristicas";
import { TourVirtual } from "../TourVirtual";
import { Sector } from "../Sector";
import { Galeria } from "../Galeria";
import { Contacto } from "../Contacto";

const Home = () => {
  return (
    <React.Fragment>
      <div id="home" className="container">
        <div id="background-color" />
        <div id="background-image" />
        <div className="home-content-container">
          <div className="home-content-container-side">
            <h1 id="title">
              <span id="preTitle">Vertientes</span>
              <br />
              de Maullín
            </h1>
            <h4 id="subtitle">Vive la naturaleza - Desde $30.000.000</h4>
            <p className="home-text">
              Ubicado a 10 minutos de Maullín, cercano a Puerto Montt y al
              areopuerto, rodeado de los sectores más bellos del sur de Chile.
            </p>
            <p className="home-text">
              Podrás encontrar el lugar que buscas para conectarte con la tierra
              en un hermoso entorno natural, a minutos del centro de Puerto
              montt, de Chiloé y de las principales zonas turísticas del sector.
            </p>
            <p className="home-text">
              Excelente oportunidad de inversión, contamos con financiamiento
              propio previa evaluación , contáctanos para más información.
            </p>
            <Button
              className="homeButton"
              variant="light"
              size="lg"
              href="#masterplan"
            >
              Ver Masterplan
            </Button>
          </div>
          <div className="home-content-container-side">
            <Contacto />
          </div>
        </div>
      </div>
      <Proyecto />
      <Caracteristicas />
      <TourVirtual />
      <Galeria />
      {/* <Sector /> */}
    </React.Fragment>
  );
};

export { Home };
