import React from "react";
import { Button } from "react-bootstrap";
import "./TourVirtual.scss";

const TourVirtual = () => {
  return (
    <section id="masterplan" className="container-fluid">
      <div className="container">
        <h2 id="title">Conoce nuestro Master Plan</h2>
        <iframe
          id="visualizadorMasterplan"
          src="https://vtour.cl/360/vertientesdemaullin/tour"
          title="W3Schools Free Online Web Tutorials"
        ></iframe>
      </div>

      <a
        href="https://vtour.cl/360/vertientesdemaullin/tour"
        rel="noreferrer"
        target="_blank"
      >
        <Button id="masterplan-button" variant="outline-light" size="lg">
          Ir a Masterplan en pantalla completa
        </Button>
      </a>
    </section>
  );
};

export { TourVirtual };
