import React from "react";
import { Button, Image } from "react-bootstrap";
import "./Caracteristicas.scss";
import projectImage from "../../assets/img/proyecto-image.jpg";
import mapImage from "../../assets/img/mapa.png";
import logo from "../../assets/img/favicon.png";

const Caracteristicas = () => {
  const features = [
    { text: "Rol propio" },
    { text: "Parcelas desde 5000 m2" },
    { text: "Autorizadas acorde a normativa legal" },
    { text: "Fácil Acceso" },
    { text: "Parcelas con orilla de camino" },
    { text: "11 Parcelas con vertientes naturales" },
    { text: "Terrenos con vistas privilegiadas" },
    { text: "Topografía plana y semiplana" },
    { text: "Factibilidad de Electricidad y Agua" },
    {
      text: "Ubicado a 30 minutos del aeropuerto del Tepual de Puerto Montt. ",
    },
    {
      text: "A 40 minutos del centro de la ciudad de Puerto Montt e inicio de la Carretera Austral",
    },
    { text: "A 19 minutos del futuro Puente del Chacao" },
    { text: "8 minutos de la confluencia del río Maullín" },
    {
      text: "Zona de Pesca: Río Maullin “El río Salvaje”.",
    },
    { text: "A 60 minutos de la famosa Carretera Austral, Ruta 7." },
  ];

  return (
    <>
      <section id="ventajas" className="container-fluid">
        <div id="ventajas-content-container" className="container">
          <h2 id="title">Ventajas</h2>
          <div id="contentContainer">
            <div id="caracteristicasContainer">
              <img id="imagen" src={projectImage} alt="Proyecto" />
            </div>
            <div id="caracteristicasContainer">
              {features.map((f, i) => (
                <div key={i} className="caracteristicaContainer">
                  <h4 className="caracteristicaIcon">
                    <Image id="listIcon" src={logo} alt="logo" />
                  </h4>
                  <h5>{f.text}</h5>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section id="ubicacion" className="container">
        <h2 id="title">Ubicación</h2>

        <div id="mapContainer">
          <Image id="mapImage" src={mapImage} alt="mapa" />
          <a
            href="https://goo.gl/maps/P8mR3BBp6wyKXSaE8"
            rel="noreferrer"
            target="_blank"
          >
            <Button variant="dark" size="lg">
              Abrir en Google Maps
            </Button>
          </a>
        </div>
      </section>
    </>
  );
};

export { Caracteristicas };
