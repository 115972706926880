import React from "react";
import "./Video.scss";
// import promotionalVideo from "../../assets/video/video_promocional.mp4";

const Video = () => {
  // const promotionalVideo =
  //   "https://player.vimeo.com/video/765422384?h=35c046b135&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479";

  return (
    <section id="video" className="container">
      <iframe
        width="100%"
        height="650px"
        src="https://www.youtube.com/embed/aLwWXj9RzKM"
        title="Vertientes de Maullín"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      {/* <video width="100%" height="auto" controls>
            <source src={"https://youtu.be/reNi6YZ3ZI0"} type="video/mp4" />
            Tu navegador no soporta los vídeos de HTML5
          </video> */}
      {/* <iframe
            src="https://player.vimeo.com/video/765422384?h=35c046b135&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            style={{
              width: "100%",
              height: "650px",
            }}
            title="Fundo Armadillo"
          ></iframe> */}
    </section>
  );
};

export { Video };
