import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import "./Proyecto.scss";
import { Video } from "../Video";

const Proyecto = () => {
  const [showVideoModal, setShowVideoModal] = useState(false);

  const handleClose = () => {
    setShowVideoModal(false);
  };

  return (
    <section id="proyecto" className="container">
      <Modal show={showVideoModal} onHide={handleClose} size="xl" centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <iframe
            width="100%"
            height="650px"
            src="https://www.youtube.com/embed/reNi6YZ3ZI0"
            title="Vertientes de Maullín"
            frameborder="0"
            allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </Modal.Body>
        <Modal.Footer id="galleryModalFooter">
          <Button variant="dark" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      <div id="textContainer">
        <h2 id="title">Proyecto Vertientes de Maullín</h2>
        <h5>
          Proyecto con 20 parcelas ubicadas a minutos Puerto Montt, en la zona
          de Maullín a solo 30 minutos del aeropuerto y 40 del centro del a
          ciudad.
          <br /> <br />
          Cuenta con parcelas planas, con vertientes naturales que pasan por más
          de 10 parcelas, además de algunas con vegetación las cuales te
          permiten apreciar toda la maravilla del sur de chile.
          <br /> <br />
          Se encuentra a minutos de ríos de pesca tales como Río Maullín
          (Santuario de la Naturaleza), los cuales son visitados año a año por
          turistas y a minutos del futuro puente del Chacao para acceder de
          manera inmediata a la belleza de Chiloé.
        </h5>
      </div>
      <Video />
    </section>
  );
};

export { Proyecto };
